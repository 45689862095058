import { FormItemRule } from "naive-ui";
import {
  minLengthMessage,
  notAllowedSpacesMessage,
  requiredMessage,
} from "@/composables/validationMessages";

const passwordValidationRules = {
  required: true,
  validator(_rule: FormItemRule, value: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!value) {
        reject(Error(`*${requiredMessage("Password")}`));
      } else if (/\s/g.test(value)) {
        reject(Error(`*${notAllowedSpacesMessage("password")}`));
      } else if (value.length < 8) {
        reject(Error(`*${minLengthMessage("Password", 8)}`));
      } else if (!/\d/.test(value)) {
        reject(Error("*Password must contain at least one digit."));
      } else if (!/[a-z]/.test(value)) {
        reject(
          Error(
            "*Password must contain at least 1 alphabetic lowercase character."
          )
        );
      } else if (!/[A-Z]/.test(value)) {
        reject(
          Error(
            "*Password must contain at least 1 alphabetic uppercase character."
          )
        );
      } else {
        resolve();
      }
    });
  },
  trigger: ["input", "blur"],
};

export default passwordValidationRules;
