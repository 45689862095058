import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41a0137c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["NSpin"], {
          size: $props.size ? $props.size * 0.9 : undefined
        }, null, 8, ["size"])
      ]))
    : _createCommentVNode("", true)
}